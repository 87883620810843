import React from 'react';
import {  Container, Row ,Col} from 'react-bootstrap';

function Header({path}) {

    return (
        <div className="header-pages"> 
            <Container fluid={true}>
                <Row className="align-items-center justify-content-center">
                    <Col>
                    <p className="text-center">{path}</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Header;
