import React, {useState}from 'react';
import { Col, Row,Container } from 'react-bootstrap';
import FancyTitle from '../components/FancyTitle';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Img1 from '../images/products/product (1).jpg';
import Img2 from '../images/products/product (2).jpg';
import Img3 from '../images/products/product (3).jpg';
import Img4 from '../images/products/product (4).jpg';
import Img5 from '../images/products/product (5).jpg';
import Img6 from '../images/products/product (6).jpg';
import Img7 from '../images/products/product (7).jpg';
import Img8 from '../images/products/product (8).jpg';
import Img9 from '../images/products/product (9).jpg';
import Img10 from '../images/products/product (10).jpg';
import Img11 from '../images/products/product (11).jpg';
import Img12 from '../images/products/product (12).jpg';
import Img13 from '../images/products/product (13).jpg';
import Img14 from '../images/products/product (14).jpg';
import Img15 from '../images/products/product (15).jpg';
import Img16 from '../images/products/product (16).jpg';
import Img17 from '../images/products/Gray T-Shirt.jpg';
import {data } from '../data';
function Product({productName, src}){
    const [hover, setHover] = useState(false)
    return(
        <Col md={6} lg={3} xs={6}>
            <div className="product d-flex align-items-center justify-content-center flex-column"
                onMouseEnter={()=> {setHover(true)}}
                onMouseLeave={()=> {setHover(false)}}
            >
                <img src={src} className="img-fluid" alt="product"/>
                <p className="h6">متاح الوان متعددة</p>
                <h4 className="text-center">{productName}</h4>
                <div className={`d-block on-hover ${hover? `d-block`: `d-md-none`}`}>
                    <button className="btn btn-orange">
                        <a href={data.whatsapp}>
                            اطلب الان
                        </a>
                    </button>
                </div>
            </div>
        </Col>  
    )   

}
function Products() {
    return (
        <div className="products-page">
                <Navbar />
                <Header path="الصفحة الرئيسية  / منتجاتنا" />

            <Container>
                <Row>
                    <Col xs={12}>
                        <FancyTitle title="منتجاتنا"/>
                    </Col>
                    <Product 
                        src={Img1}
                        productName="تيشيرت بولو"
                        />
                        
                        <Product 
                        src={Img2}
                        productName="تيشيرت بيسيك"
                        />
                        
                        <Product 
                        src={Img3}
                        productName="كاب شيف"
                        />
                        
                        <Product 
                        src={Img4}
                        productName="جاكت شيف"
                        />
                        
                        <Product 
                        src={Img5}
                        productName="بالطو"
                        />
                        
                        <Product 
                        src={Img7}
                        productName="ابرون (مريلة)"
                        />
                        
                        <Product 
                        src={Img8}
                        productName="كاب جبردين"
                        />
                        
                        <Product 
                        src={Img9}
                        productName="جاكت ووتر بروف"
                        />
                        
                        <Product 
                        src={Img10}
                        productName="جاون"
                        />
                        
                        <Product 
                        src={Img11}
                        productName="فيست عاكس"
                        />
                        
                        <Product 
                        src={Img12}
                        productName="بدلة طبية"
                        />
                        
                        <Product 
                        src={Img13}
                        productName="اوفرول"
                        />
                        
                        <Product 
                        src={Img14}
                        productName="قميص"
                        />
                        
                        <Product 
                        src={Img15}
                        productName="تيشيرت بولو قطن"
                        />

                        <Product 
                        src={Img16}
                        productName="ماسك"
                        />

                        <Product 
                        src={Img17}
                        productName="تيشيرت كم طويل"
                        />
                </Row>
            </Container>
            <Footer/>
        </div>
    )
}

export default Products;
