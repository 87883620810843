import React from 'react'
import {Container} from 'react-bootstrap';
import FancyTitle from './FancyTitle';
import Logo1 from '../images/logo (1).png';
import Logo2 from '../images/logo (1).jpg';
import Logo3 from '../images/logo (11).jpg';
import Logo4 from '../images/logo (12).jpg';
import Logo5 from '../images/logo (13).jpg';
import Logo6 from '../images/logo (14).jpg';
import Logo7 from '../images/logo (15).jpg';
import Logo8 from '../images/logo (16).jpg';
import Logo9 from '../images/logo (17).jpg';
import Logo10 from '../images/logo (18).jpg';
import Logo11 from '../images/logo (19).jpg';
import Logo12 from '../images/logo (2).jpg';
import Logo13 from '../images/logo (20).jpg';
import Logo14 from '../images/logo (21).jpg';
import Logo15 from '../images/logo (23).jpg';
import Logo16 from '../images/logo (29).jpg';
import Logo17 from '../images/logo (24).jpg';
import Logo18 from '../images/logo (3).jpg';
import Logo19 from '../images/logo (31).jpg';
import Logo20 from '../images/logo (33).jpg';
import Logo21 from '../images/logo (36).jpg';
import Logo22 from '../images/logo (39).jpg';
import Logo23 from '../images/logo (4).jpg';
import Logo24 from '../images/logo (40).jpg';
import Logo25 from '../images/logo (46).jpg';
import Logo26 from '../images/logo (47).jpg';
import Logo27 from '../images/logo (48).jpg';
import Logo28 from '../images/logo (5).jpg';
import Logo29 from '../images/logo (52).jpg';
import Logo30 from '../images/logo (53).jpg';
import Logo31 from '../images/logo (55).jpg';
import Logo32 from '../images/logo (59).jpg';
import Logo33 from '../images/logo (6).jpg';
import Logo34 from '../images/logo (61).jpg';
import Logo35 from '../images/logo (62).jpg';
import Logo36 from '../images/logo (69).jpg';
import Logo37 from '../images/logo (7).jpg';
import Logo38 from '../images/logo (8).jpg';
import Logo39 from '../images/logo (9).jpg';
import Logo40 from '../images/logo (61).jpg';
function Client({src}){
    return(
        <div className="img-box">
            <img alt="our-work-img" src={src} className="img-fluid"/>
        </div>
    )
}
function OurClinets() {
    return (
        <div className="our-clients">
            <Container fluid={true}>
                <div className="heading text-center">
                    <FancyTitle title="عملائنا" />
                </div>
                <div className="row-container">
                    <div className="row-animtion">
                        <div className="animation-box">
                            <Client src={Logo1}/>
                            <Client src={Logo2}/>
                            <Client src={Logo3}/>
                            <Client src={Logo4}/>
                            <Client src={Logo5}/>
                            <Client src={Logo6}/>
                            <Client src={Logo7}/>
                            <Client src={Logo8}/>
                            <Client src={Logo9}/>
                            <Client src={Logo10}/>
                            <Client src={Logo11}/>
                            <Client src={Logo12}/>
                            <Client src={Logo13}/>
                            <Client src={Logo14}/>
                            <Client src={Logo15}/>
                            <Client src={Logo16}/>
                            <Client src={Logo17}/>
                            <Client src={Logo18}/>
                            <Client src={Logo19}/>
                            <Client src={Logo20}/>
                        </div>
                        <div className="animation-box">
                            <Client src={Logo21}/>
                            <Client src={Logo22}/>
                            <Client src={Logo23}/>
                            <Client src={Logo24}/>
                            <Client src={Logo25}/>
                            <Client src={Logo26}/>
                            <Client src={Logo27}/>
                            <Client src={Logo28}/>
                            <Client src={Logo29}/>
                            <Client src={Logo30}/>
                            <Client src={Logo31}/>
                            <Client src={Logo32}/>
                            <Client src={Logo33}/>
                            <Client src={Logo34}/>
                            <Client src={Logo35}/>
                            <Client src={Logo36}/>
                            <Client src={Logo37}/>
                            <Client src={Logo38}/>
                            <Client src={Logo39}/>
                            <Client src={Logo40}/>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default OurClinets
