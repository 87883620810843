import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import HeaderImg from '../images/about-img.png';
import {Link } from 'react-router-dom';
import divider from '../images/wave (5).svg';
import {data} from '../data';

function HomeHeader() {
    return (
        <div className="home-header">
            <Container>
                <Row className="align-items-center">
                    <Col md={6} className="right text-center text-md-start">
                        <h2>بي يونيفورم</h2>
                        <p>
                          بي يونيفورم لتوريد الزي الرسمي واليونيفورم لاي جهة عمل باسعار مناسبة وخامات عالية الجودة مع امكانية التنفيذ حسب طلب العميل

                        - متخصصون فى عمل جميع انواع الزى الموحد لكبرى الشركات والمؤسسات بمصر وجميع الدول العربية 

                        - تقديم افضل خدمة فى مجال الزى الموحد والدقة فى التعامل والتسليم فى المواعيد المحددة وبنفس المواصفات المطلوبة
                           </p>
                        <button className="btn btn-header btn-orange">
                            <a href={data.whatsapp}>
                                تواصل الان
                            </a>
                        </button>
                    </Col>
                    <Col md={6}>
                        <img alt="img" src={HeaderImg} className="img-fluid"/>
                    </Col>
                </Row>
            </Container>
            <div className="divider">
                <img src={divider} alt="divider"/>
            </div>
        </div>
    )
}

export default HomeHeader;
