import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';

function Feature({icon, heading, par}){
    return(
        <div className="feature text-center">
            <i className={`fa fa-${icon} feature-icon`}></i>
            <h3>{heading}</h3>
            <p>{par}</p>
        </div>
    )
}

function Features() {
    return (
        <div className="features">
            <Container>
                <Row>
                    <Col md={6} lg={4}>
                        <Feature
                            icon="allergies"
                            heading="
                                جودة عالية
                            "
                            par="
                               اختيار الخامات المناسبة لكل مجال 
                            " />
                    </Col>
                    <Col md={6} lg={4}>
                        <Feature
                            icon="history"
                            heading="
                            سرعة التنفيذ

                            "
                            par="
                                وقت أقل تنفيذ دقيق
                            " />
                    </Col>
                    <Col md={6} lg={4}>
                        <Feature
                            icon="grin-beam"
                            heading="
                                تعاون مثمر
                            "
                            par="
                                شركائك في النجاح
                            " />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Features;
