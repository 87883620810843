import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import FancyTitle from './FancyTitle';
import ImgSrc1 from '../images/our-work (1).jpg';
import ImgSrc2 from '../images/our-work (2).jpg';
import ImgSrc3 from '../images/about-cap.png';
import ImgSrc4 from '../images/our-work (10).jpg';
import ImgSrc5 from '../images/our-work-(15).png';
import ImgSrc7 from '../images/our-work (11).jpg';
import ImgSrc8 from '../images/our-work-(12).png';
import ImgSrc6 from '../images/our-work-(13).png'
import ImgSrc9 from '../images/our-work-(14).png';

function OurWork() {
    return (
        <div className="our-work">
            
            <Container>
                <Row>
                    <Col md={12}>
                        <FancyTitle title="أخر أعمالنا"/>
                    </Col>
                    <Col md={12}>
                        <div className="gallery">
                            <Row>
                                <Col md={6} lg={4} className="d-flex justify-content-center align-items-center">
                                    <img alt="our-work-img" src={ImgSrc1} className="img-fluid our-work-img"/>
                                </Col>
                                <Col md={6} lg={4} className="d-flex justify-content-center align-items-center">
                                    <img alt="our-work-img" src={ImgSrc2} className="img-fluid our-work-img"/>
                                </Col>
                                <Col md={6} lg={4} className="d-flex justify-content-center align-items-center">
                                    <img alt="our-work-img" src={ImgSrc3} className="img-fluid our-work-img"/>
                                </Col>
                                <Col md={6} lg={4} className="d-flex justify-content-center align-items-center">
                                    <img alt="our-work-img" src={ImgSrc4} className="img-fluid our-work-img"/>
                                </Col>
                                <Col md={6} lg={4} className="d-flex justify-content-center align-items-center">
                                    <img alt="our-work-img" src={ImgSrc5} className="img-fluid our-work-img"/>
                                </Col>
                                <Col md={6} lg={4} className="d-flex justify-content-center align-items-center">
                                    <img alt="our-work-img" src={ImgSrc6} className="img-fluid our-work-img"/>
                                </Col>
                                <Col md={6} lg={4} className="d-flex justify-content-center align-items-center">
                                    <img alt="our-work-img" src={ImgSrc7} className="img-fluid our-work-img"/>
                                </Col>
                                <Col md={6} lg={4} className="d-flex justify-content-center align-items-center">
                                    <img alt="our-work-img" src={ImgSrc8} className="img-fluid our-work-img"/>
                                </Col>
                                    <Col md={6} lg={4} className="d-flex justify-content-center align-items-center">
                                    <img alt="our-work-img" src={ImgSrc9} className="img-fluid our-work-img"/>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default OurWork
