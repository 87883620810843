import React from 'react'
import ProductsSlider from './ProductsSlider';
import FancyTitle from './FancyTitle';
import wave from '../images/product-under-wave.svg'

function OurProducts() {
    return (
        <div className="our-products"> 
             <FancyTitle title="منتاجتنا"/>
            <ProductsSlider/>
            <div className="product-under-wave">
                <img alt="wave" className="img-fluid" src={wave}/>
            </div>
        </div>
    )
}

export default OurProducts
