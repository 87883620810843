import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';    
import {data } from '../data';
function Footer() {
    return (
        <div className="footer">
            <Container fluid={true}>
                <Row className="justify-content-center align-items-center">
                    <Col md={3}>
                        <div className="social-links text-center">
                            <p className="h3">تابعنا علي :</p>
                            <ul className="justfiy-content-center text-center d-flex align-items-center list-unstyled">
                                <li className="col ">
                                    <a href={data.facebook} >
                                        <i className="fab fa-facebook"></i>
                                    </a>
                                </li>
                                <li className=" col">
                                    <a href={data.instagram}>
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li className="col">
                                    <a href={data.whatsapp}>
                                        <i className="fab fa-whatsapp"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={12} className="copy-right justify-self-end text-center">
                        <p className="h4 text-ceneter">جميع الحقوق محفوظة لدي @beeUniform</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer;
