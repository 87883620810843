import React from 'react';
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import AboutComponent from '../components/AboutComponent';
import Footer from '../components/Footer';
function About() {
    return (
        <div className="about-page">
            <Navbar />
            <Header path="الصفحة الرئيسية  / من نحن" />
            <AboutComponent />
            <Footer />
        </div>
    )
}

export default About;
