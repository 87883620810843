import React from 'react';
import Slider from "react-slick";
import Img1 from '../images/products/product (1).jpg';
import Img2 from '../images/products/product (2).jpg';
import Img3 from '../images/products/product (3).jpg';
import Img4 from '../images/products/product (4).jpg';
import Img5 from '../images/products/product (5).jpg';
import Img7 from '../images/products/product (7).jpg';
import Img8 from '../images/products/product (8).jpg';
import Img9 from '../images/products/product (9).jpg';
import Img10 from '../images/products/product (10).jpg';
import Img11 from '../images/products/product (11).jpg';
import Img12 from '../images/products/product (12).jpg';
import Img13 from '../images/products/product (13).jpg';
import Img14 from '../images/products/product (14).jpg';
import Img15 from '../images/products/product (15).jpg';
import {Link } from 'react-router-dom';
function ProductsSlider() {
    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 3,
        arrows: false,
        dots: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,    
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,    
                }
            }
        ]
      };
    return (
            <Slider {...settings}>
                <div className="product d-flex flex-column">
                    <img src={Img1} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img2} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img3} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img4} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img5} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
               
                <div className="product d-flex flex-column">
                    <img src={Img7} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img8} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img9} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img10} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img11} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img12} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img13} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img14} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
                <div className="product d-flex flex-column">
                    <img src={Img15} alt="product" className="img-fluid product-img"/>
                    <button className="btn btn-orange">
                        <Link to="/منتجاتنا">
                        تصفح المنتجات
                        </Link>
                        </button>
                </div>
            </Slider>
    )
}

export default ProductsSlider;
