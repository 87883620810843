import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import img from '../images/headerimg.png';
import FancyTitle from './FancyTitle';
import shipping from '../images/shiping.png';
import imgHeight from '../images/shirt-about.png';
import shirtImg from '../images/about-img.png';
function AboutComponent() {
    return (
        <div className="about-component">
            <Container>
                <Row className="align-items-center justify-content-center">
                    <Col md={12} className="text-center">
                        <FancyTitle title="من نحن؟"/>
                    </Col>
                    <Col lg={9} md={12}>
                        <div className="feature ">
                            <Row >
                                <Col md={2}>
                                    <div className="icon">
                                        <span>.01</span>
                                    </div>
                                </Col>
                                <Col md={9}>
                                    <Row className="box ">
                                        <Col md={7}>
                                            <div className="content">
                                                <h4>كافة خدمات التصنيع والتطريز والطباعة</h4>
                                                <p>نهدف الي ارضاء العميل وذ لك من خلال توفير مختلف خدمات التصنيع من توفير القماش وعمل لوجو  طباعة او تطريز كل ذلك في مكان واحد</p>
                                            </div>
                                        </Col>
                                        <Col md={5}>
                                            <div className="img-feature d-flex align-items-center justify-content-center">
                                                <img src={imgHeight} alt="feature" className="img-fluid" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="feature  " style={{direction: `ltr`}}>
                            <Row>
                                <Col md={2}>
                                    <div className="icon">
                                        <span>.02</span>
                                    </div>
                                </Col>
                                <Col md={10}>
                                    <Row className="box left" >
                                        <Col md={7}>
                                            <div className="content text-start">
                                                <h4>جودة التصنيع وسرعة التنفيذ </h4>
                                                <p>نهدف الي توفير الوقت علي العملاء والموردين حيث  تلتزم الشركة بالمواعيد المتفق عليها في عقد التنفيذ وفي اسرع وقت تنفيذ ممكن مع مراعاة المواصفات والمعايير عالية الجودة للمنافسة في السوق العالمي</p>
                                            </div>
                                        </Col>
                                        <Col md={5}>
                                            <div className="img-feature d-flex align-items-center justify-content-center">
                                                <img src={shirtImg} alt="feature" className="img-fluid" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="feature  ">
                            <Row>
                                <Col md={2}>
                                    <div className="icon">
                                        <span>.03</span>
                                    </div>
                                </Col>
                                <Col md={10}>
                                    <Row className="box ">
                                        <Col md={6}>
                                            <div className="content">
                                                <h4>امكانية التصدير الي كل الدول</h4>
                                                <p>نهدف الي تبوء دور الريادة في تصنيع وتصدير منتجاتها الي كافة الدول العربية والافريقية وفتح سوق مشتركة </p>
                                            </div>
                                        </Col>
                                        <Col md={5}>
                                            <div className="img-feature d-flex align-items-center justify-content-center">
                                                <img src={shipping} alt="feature" className="img-fluid" />
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default AboutComponent;
