import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import ServiceImg from '../images/headerimg.png'
import {Link} from 'react-router-dom';
import ImdDivider from '../images/book (9).svg';
import FancyTitle from './FancyTitle';
import wave from '../images/our-product-wave.svg';
import pr1 from '../images/products/products.png';
import pr2 from '../images/products/products-1.png';
function Service({src, serviceName, par,order}){
    return(
        <div className="service text-center text-md-start">
            <Row className="align-items-center justify-content-around">
                <Col  md={{order: order, span:4}}  >
                    <div className="img-service">
                        <img alt="service img" className={`img-fluid ${order? ``: ` img-order`}`} src={src}/>
                    </div>
                </Col>
                <Col md={6} >
                    <div className="service-content">
                        <h3 className="service-name">{serviceName}</h3>
                        <p>{par}</p>
                        <button className="btn btn-orange">
                            <Link to="/contact">
                                اطلب الخدمة
                            </Link>
                            </button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

function Services() {
    return (
        <div className="services">
            <div className="divider-service">
                <img  alt="wave" src={ImdDivider}/>
            </div>
            <Container>
                <Row>
                    <Col md={12}>
                        <FancyTitle title="ماذا نعمل؟"/>
                    </Col>
                    <Col md={12}>
                       <Service src={pr2}
                        serviceName="
                             تطريز يونيفورم
                            " 
                        par="
                         تطريز جميع انواع الزى الموحد وظهور  البراند الخاص بك علي افضل مايكون باعلي جودة
                        "
                        order= "1"
                        />
                    </Col>
                    <Col md={12}>
                       <Service src={pr1}
                       serviceName="
                       طباعة يونيفورم
                       " 
                       par="
                         طباعة جميع انواع الزى الموحد وابراز العلامه التجارية علي افضل مايكون باعلي جودة
باحدث تكنولوجي  
                        "/>
                    </Col>  
                    
                </Row>
            </Container>
            <div className="product-wave">
                <img alt="wave" className="img-fluid" src={wave}/>
            </div>
        </div>
    )
}

export default Services;
