import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './Pages/Home';
import About from './Pages/About';
import ContactUs from './Pages/ContactUs';
import Products from './Pages/Products';
import ActionsBtn from './components/ActionsBtn';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>  
          <Route exact path="/" component={Home}/>
          <Route  path="/منتجاتنا" component={Products}/>
          <Route  path="/contact" component={ContactUs}/>
          <Route  path="/من نحن" component={About}/>
        </Switch>
      </Router>
      <ActionsBtn />
    </div>
  );
}

export default App;
