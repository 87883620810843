import React from 'react';
import {data} from '../data';
function ActionsBtn() {
    const scrollTop = () => {
        window.scroll(0, 0);
    }
    return (
        <div className="actions-btn"> 
            <div className="up-btn">
                <button className=" btn " onClick={scrollTop}>
                    <i className="fa fa-chevron-circle-up"></i>
                </button>
            </div>
            <div className="whatsapp-btn">
                <a href={`${data.whatsapp}`}>
                    <button className="btn">
                        <i className="fab fa-whatsapp"></i>
                    </button>
                </a>
            </div>
        </div>
    )
}

export default ActionsBtn;
