import React from 'react';
import Navbar from '../components/Navbar';
import HomeHeader from '../components/HomeHeader';
import Features from '../components/Features';
import Services from '../components/Services';
import OurProducts from '../components/OurProducts';
import OurWork from '../components/OurWork';
import OurClinets from '../components/OurClinets';
import Footer from '../components/Footer';
import ActionsBtn from '../components/ActionsBtn';

function Home() {
    return (
        <div className="home-page">
            <Navbar/>
            <HomeHeader/>
            <Features/>
            <Services/>
            <OurProducts/>
            <OurWork/>
            <OurClinets/>
            <Footer/>
            <ActionsBtn/>
        </div>
    )
}

export default Home;
