import React from 'react';
import Line from '../images/fancy-line.png';

function FancyTitle({title}) {
    return (
        <div>
            <div className="fancy-title text-center">
                <h4 className="h1">
                    <img alt="خط" src={Line} className="img-fluid d-none d-lg-inline" />
                    {title}
                    <img alt="خط" src={Line} className="img-fluid d-none d-lg-inline" />
                </h4>
            </div>
        </div>
    )
}

export default FancyTitle;
