import React, { useState, useLayoutEffect} from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Logo from '../images/favicon.png';
import { Link,NavLink } from 'react-router-dom';

function Navbar() { 
    const [navActive, setNavState] = useState(false);
    const [navOpen, setNavOpen] = useState(false);
    const [scrollHeigh , setScrollHeight] = useState(10)

    /* check scroll for nav */
    const checkScroll = () => {
        window.scrollY < 50 ? setNavState(false) : setNavState(true);
    }

    /* det scroll bar width every scroll */
    useLayoutEffect(() => {
        setScrollHeight(0);
        window.addEventListener('scroll', () => {
            const height =  ((window.scrollY + window.innerHeight) / ( document.body.clientHeight )*100) * (100 / 100) ;
            setScrollHeight(height);
        });
        window.addEventListener('scroll', checkScroll);
        return () => {
            window.removeEventListener('scroll', checkScroll);
        }
    },[]);

    
    return ( 
    <div className = { `my-navbar ${navActive? `nav-dark`: ` ` }`}>
        <div className="scroll-bar" style={{width: `${scrollHeigh}%`}}></div>
        <Container fluid={true}>
            <Row className="align-items-center justify-content-between">
                <Col xs={4} className="d-md-none d-block">
                    <div className="toggle-icon" onClick={()=> navOpen? setNavOpen(false) :  setNavOpen(true)}>
                        <i className={` fa fa-${navOpen?`times` : `bars `}`}></i>
                    </div>
                </Col>
                <Col md={2} xs={3}>
                    <div className="nav-logo">
                        <Link to="/" >
                            <img src={Logo} alt="bee logo" className="img-fluid"/>
                        </Link>
                    </div>
                </Col>
                <Col md={9} className={` d-md-block menu-mob ${navOpen?`open` : ` `}`}>
                    <div className="pages-links">
                        <ul className="row list-unstyled align-items-center">
                            <li className="col-md-3">
                                <NavLink exact to="/" className="link" activeClassName="link-selected">
                                    الرئيسية
                                </NavLink>
                            </li>
                            <li className="col-md-3">
                                <NavLink to="/من نحن" className="link" activeClassName="link-selected">
                                    من نحن؟
                                </NavLink>
                            </li>
                            <li className="col-md-3">
                                <NavLink to="/منتجاتنا" className="link" activeClassName="link-selected">
                                    منتجاتنا
                                </NavLink>
                            </li>
                            <li className="col-md-3">
                                <NavLink to="/contact" className="link" activeClassName="link-selected">
                                    تواصل معنا
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default Navbar;