import React, {useRef, useState} from 'react'
import { Col, Row,Container } from 'react-bootstrap';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import logo from '../images/favicon.png';
import {data} from '../data';


function ContactUs() {
    const btnRef = useRef();
    const numberRef = useRef();
    const [showCopied, setShowCopied] = useState(false);
    const  copyTo = () => {
        const text = numberRef.current.textContent;
        navigator.clipboard.writeText(text);
        console.log(text);
        setShowCopied(true);
        setTimeout(()=> {
            setShowCopied(false);
        },3000 );
    }
    return (
        <div className="contact-page">
            <Navbar />
            <Header path="الرئيسية / تواصل معنا" /> 
            <Container>
                <Row className="boxes-row d-flex justify-content-center align-items-center">
                    <Col md={3}>
                        <div style={{"backgroundColor": "#128C7E"}} className="social_box  d-flex justify-content-center align-items-center">
                            <button onClick={copyTo} className="btn" ref={btnRef}>
                                <span ref={numberRef}><a href={`tel:${data.phone1}`}>
                                {data.phone1}
                                </a></span> 
                                 <i className="fa fa-phone"> </i>
                                 {
                                    showCopied?( <span className="copied">تم نسخ الرقم</span> ) : null

                                 }
                            </button>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div style={{"backgroundColor": "#0489c9"}} className="social_box  d-flex justify-content-center align-items-center">
                            <a href="/"> 
                                    {data.email}
                                <i class="fa fa-envelope-open-text">
                                </i>
                            </a>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div style={{"backgroundColor": "#25D366"}} className="social_box  d-flex justify-content-center align-items-center">
                            <a href={`${data.whatsapp}`}>
                                    {data.phone1} 
                                <i className="fab fa-whatsapp">
                                </i>
                            </a>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div style={{"backgroundColor": "#4267B2"}} className="social_box  d-flex justify-content-center align-items-center">
                            <a href={`${data.facebook}`}> 
                                    bee Uniform
                                <i class="fab fa-facebook-messenger">
                                </i>
                            </a>
                        </div>
                    </Col>
                </Row>
                <form>
                    <Row className="justify-content-between align-items-center">
                        <Col md={7}  xs={{span:12, order:1}}>
                            <Row>
                                <Col lg={6} md={12} >
                                    <div className="input-field d-flex justify-content-center flex-column">
                                        <label>الاسم :</label>
                                        <input required type="text" htmlfor="name"/>
                                    </div>
                                </Col>
                                <Col lg={6} md={12} >
                                    <div className="input-field d-flex justify-content-center flex-column">
                                        <label>الايميل :</label>
                                        <input required type="email" htmlfor="email" />
                                    </div>
                                </Col>
                                <Col lg={6} md={12}>
                                    <div className="input-field d-flex justify-content-center flex-column">
                                        <label>رقم الهاتف :</label>
                                        <input required htmlfor="phone" type="text"/>
                                    </div>
                                </Col>
                                <Col lg={6} md={12} >
                                    <div className="input-field d-flex justify-content-center flex-column">
                                        <label>
                                            المنتج :
                                        </label>
                                        <select required>
                                            <option value="تيشيرت بولو">
                                                 تيشيرت بولو
                                            </option>
                                            <option value="كاب">
                                                كاب
                                            </option>
                                            <option value="سكراب طبي">
                                                سكراب طبي
                                            </option>
                                            <option value="بالطو طبي">
                                                بالطو طبي
                                            </option>
                                            <option value="اوفرول">
                                                اوفرول
                                            </option>
                                            <option value="قميص">
                                                قميص
                                            </option>
                                            <option value="جاكت شيف">جاكت شيف</option>
                                            <option value="ابرون">ابرون</option>
                                            <option value="جاكت وتر بروف">جاكت وتر بروف</option>
                                            <option value="تيشيرت بيس">تيشيرت بيسك</option>
                                        </select>
                                    </div>
                                </Col>
                                <Col lg={6} md={12} >
                                    <div className="input-field d-flex justify-content-center flex-column">
                                        <label>
                                            الخدمة :
                                        </label>
                                        <select required>
                                            <option value="طباعة">
                                                 طباعة
                                            </option>
                                            <option value="تطريز">
                                            تطريز
                                            </option>
                                        </select>
                                    </div>
                                </Col>                              
                                <Col lg={6} md={12} >
                                    <div className="input-field d-flex justify-content-center flex-column">
                                        <label>الكمية : </label>
                                        <select required>
                                            <option value="50-100">
                                                 50-100
                                            </option>
                                            <option value="100-500">
                                                 100-500
                                            </option>
                                            <option value="500-1000">
                                                 500-1000
                                            </option>
                                        </select>
                                    </div>
                                </Col>
                                <Col lg={12} md={12}>
                                    <input  type="submit" value="تقديم الطلب" className="btn btn-orange submit" />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}  xs={{span:12, order:0}}>
                            <div className="d-flex  justify-content-center">
                                <img className="img-fluid" src={logo} alt="بي يونيفورم"/>
                            </div>
                        </Col>
                       </Row>
                </form>
            </Container>
            <Footer />
        </div>
    )
}

export default ContactUs;
